body {
  background-color: #282c34;
}

.page {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
}

.page-map {
  background-color: #282c34;
  min-height: 100vh;
  color: white;
}

.btn-report {
  position: absolute;
  top: 110px;
  left: 10px;
  z-index: 100000;
  background-color: orange;
  width: 80px;
  height: 80px;
}

.page-topbar {
  height: 50px;
}

.page-topbar .topbar-menu {
  float: left;
  height: 100%;
}

.page-topbar .topbar-menu .btn {
  margin-left: 10px;
}

.page-topbar .topbar-menu .menu-item {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  cursor: pointer;
  height: 100%;
}

.page-topbar .topbar-menu .menu-item-dropdown {
  float: left;
  overflow: hidden;
}

.page-topbar .topbar-menu .menu-item-dropdown .menu-item-dropdown-content {
  margin-top: 50px;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.page-topbar .topbar-menu .menu-item-dropdown:hover .menu-item-dropdown-content {
  display: block;
}

.page-topbar .topbar-menu .menu-item-dropdown .menu-item-dropdown-content .menu-item {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}


/* Change the color of links on hover */
.page-topbar .topbar-menu .menu-item:hover,
.page-topbar .topbar-menu .menu-item-dropdown:hover {
  background-color: #ddd;
  color: black;
}

.page-topbar .topbar-menu .menu-item-dropdown:hover .menu-item {
  color: black;
}

/* Add a color to the active/current link */
.page-topbar .topbar-menu .menu-item.active {
  background-color: #04AA6D;
  color: white;
}

.page-topbar .topbar-right {
  float: right;
  height: 100%;
}

.page-topbar .logo-container {
  float: left;
  background-color: white;
  height: 100%;
  width: auto;
}

.page-topbar .logo-container .topbar-logo {
  height: 100%
}

.page-reports .report-block-boundaries,
.page-reports .report-download {
  width: 100%;
}

.report-container {
  margin-top: 20px;
}

.report {
  background-color: white;
  color: black;
}

.overview-map-container {
  height: calc(100vh - 50px);
  width: 100%;
}

.map-container {
  width: 100%;
  height: 100%;
}

.map-view {
  width: 100%;
  height: 100%;
}


.report table, .report th, .report td {
  border: 1px solid black;
  border-collapse: collapse;
}

.report th, .report td {
  padding: 0 10px 0 5px;
}

.report-page .report-map-view-container {
  width: 100%;
  height: 500px;
}

/* Print Page Rules */
.report-print .page-a4 {
  width: 210mm;
  height: 297mm
}

.report-print .report-page .report-map-view-container {
  height: 270mm;
}

@media print
{
  .page-break  { height: 0; width: 0; display:block; page-break-before:always; }
  .page-topbar {
    display: none;
  }
}

.report-page.report-page-details td,
.report-page.report-page-daily-stats td {
  background-color:#eee;
}
.report-page.report-page-details th,
.report-page.report-page-daily-stats th {
  background-color:#009;
  color:#fff;
}

.report-page.report-page-details td,
.report-page.report-page-daily-stats td,
.report-page.report-page-details th,
.report-page.report-page-daily-stats th {
  padding: .25em .5em;
  text-align: left;
}

.report-page.report-page-details td:nth-child(1),
.report-page.report-page-daily-stats td:nth-child(1),
.report-page.report-page-details th:nth-child(1),
.report-page.report-page-daily-stats th:nth-child(1) {
   text-align:right;
}


.login-logo {
  margin-bottom: 10px;
}

.user-tag-container {
  height: 100%;
  line-height: 50px;
  margin-right: 10px;
  color: white;
}

.report-page .company-logo {
  width: 80%;
  margin: auto;
}

.report-page.report-page-comments .report-comments-container {
  padding: 5px;
  width: 100%;
  border: black 1px solid;
}